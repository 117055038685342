import '@fontsource/lato'
import './src/assets/css/normalize.css'
import './src/assets/scss/style.scss'

export const onClientEntry = async () => {
	class FitToContainer extends HTMLElement {
		constructor() {
			super()
			console.log(`Using custom element: 'fit-to-container'`)
		}
	}
	customElements.define(`fit-to-container`, FitToContainer)

	class FloatRight extends HTMLElement {
		constructor() {
			super()
			console.log(`Using custom element: 'float-right'`)
		}
	}
	customElements.define(`float-right`, FloatRight, { extends: `figure` })

	class GoldenWidth extends HTMLElement {
		constructor() {
			super()
			console.log(`Using custom element: 'golden-width'`)
		}
	}
	customElements.define(`golden-width`, GoldenWidth, { extends: `figure` })
}
