exports.components = {
  "component---gatsby-theme-2023-src-pages-elements-mjs": () => import("./../../../../gatsby-theme-2023/src/pages/elements.mjs" /* webpackChunkName: "component---gatsby-theme-2023-src-pages-elements-mjs" */),
  "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2015-10-if-your-up-front-planning-is-measured-in-weeks-then-a-lean-startup-is-going-to-eat-your-lunch-mdx": () => import("./../../../../gatsby-theme-blog/src/templates/post.js?__contentFilePath=/opt/build/repo/core/src/articles/2015/10/If Your Up-Front Planning is Measured in Weeks, Then a Lean Startup is Going to Eat Your Lunch.mdx" /* webpackChunkName: "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2015-10-if-your-up-front-planning-is-measured-in-weeks-then-a-lean-startup-is-going-to-eat-your-lunch-mdx" */),
  "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2016-06-etymology-of-management-mdx": () => import("./../../../../gatsby-theme-blog/src/templates/post.js?__contentFilePath=/opt/build/repo/core/src/articles/2016/06/Etymology of Management.mdx" /* webpackChunkName: "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2016-06-etymology-of-management-mdx" */),
  "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2016-11-book-review-the-great-scrum-master-by-zuzana-sochova-mdx": () => import("./../../../../gatsby-theme-blog/src/templates/post.js?__contentFilePath=/opt/build/repo/core/src/articles/2016/11/Book Review, the Great ScrumMaster by Zuzana Sochova.mdx" /* webpackChunkName: "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2016-11-book-review-the-great-scrum-master-by-zuzana-sochova-mdx" */),
  "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2017-01-a-week-in-the-life-of-a-scrum-team-mdx": () => import("./../../../../gatsby-theme-blog/src/templates/post.js?__contentFilePath=/opt/build/repo/core/src/articles/2017/01/A Week in the Life of a Scrum Team.mdx" /* webpackChunkName: "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2017-01-a-week-in-the-life-of-a-scrum-team-mdx" */),
  "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2017-10-a-scrum-team-increases-their-velocity-by-doing-less-work-mdx": () => import("./../../../../gatsby-theme-blog/src/templates/post.js?__contentFilePath=/opt/build/repo/core/src/articles/2017/10/A Scrum Team Increases Their Velocity by Doing Less Work.mdx" /* webpackChunkName: "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2017-10-a-scrum-team-increases-their-velocity-by-doing-less-work-mdx" */),
  "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2018-04-canadas-phoenix-project-is-the-reason-scrum-is-important-mdx": () => import("./../../../../gatsby-theme-blog/src/templates/post.js?__contentFilePath=/opt/build/repo/core/src/articles/2018/04/Canada's Phoenix Project is the Reason Scrum is Important.mdx" /* webpackChunkName: "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2018-04-canadas-phoenix-project-is-the-reason-scrum-is-important-mdx" */),
  "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2018-08-looking-for-work-as-a-scrum-master-choose-wisely-mdx": () => import("./../../../../gatsby-theme-blog/src/templates/post.js?__contentFilePath=/opt/build/repo/core/src/articles/2018/08/Looking for Work as a Scrum Master - Choose Wisely.mdx" /* webpackChunkName: "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2018-08-looking-for-work-as-a-scrum-master-choose-wisely-mdx" */),
  "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2018-11-the-art-of-agile-product-documentation-mdx": () => import("./../../../../gatsby-theme-blog/src/templates/post.js?__contentFilePath=/opt/build/repo/core/src/articles/2018/11/The Art of Agile Product Documentation.mdx" /* webpackChunkName: "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2018-11-the-art-of-agile-product-documentation-mdx" */),
  "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2018-12-i-am-a-pst-with-scrum-org-mdx": () => import("./../../../../gatsby-theme-blog/src/templates/post.js?__contentFilePath=/opt/build/repo/core/src/articles/2018/12/I am a PST with Scrum.org.mdx" /* webpackChunkName: "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2018-12-i-am-a-pst-with-scrum-org-mdx" */),
  "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2019-06-velocity-escape-this-pitfall-mdx": () => import("./../../../../gatsby-theme-blog/src/templates/post.js?__contentFilePath=/opt/build/repo/core/src/articles/2019/06/Velocity- Escape this Pitfall.mdx" /* webpackChunkName: "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2019-06-velocity-escape-this-pitfall-mdx" */),
  "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2019-07-team-topologies-mdx": () => import("./../../../../gatsby-theme-blog/src/templates/post.js?__contentFilePath=/opt/build/repo/core/src/articles/2019/07/Team Topologies.mdx" /* webpackChunkName: "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2019-07-team-topologies-mdx" */),
  "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2019-09-certifications-if-i-were-you-mdx": () => import("./../../../../gatsby-theme-blog/src/templates/post.js?__contentFilePath=/opt/build/repo/core/src/articles/2019/09/Certifications - If I Were You.mdx" /* webpackChunkName: "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2019-09-certifications-if-i-were-you-mdx" */),
  "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2019-09-dislocated-scrum-teams-mdx": () => import("./../../../../gatsby-theme-blog/src/templates/post.js?__contentFilePath=/opt/build/repo/core/src/articles/2019/09/Dislocated Scrum Teams.mdx" /* webpackChunkName: "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2019-09-dislocated-scrum-teams-mdx" */),
  "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2019-09-learning-path-scrum-mastery-mdx": () => import("./../../../../gatsby-theme-blog/src/templates/post.js?__contentFilePath=/opt/build/repo/core/src/articles/2019/09/Learning Path - Scrum Mastery.mdx" /* webpackChunkName: "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2019-09-learning-path-scrum-mastery-mdx" */),
  "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2019-09-learning-path-solutions-architects-mdx": () => import("./../../../../gatsby-theme-blog/src/templates/post.js?__contentFilePath=/opt/build/repo/core/src/articles/2019/09/Learning Path - Solutions Architects.mdx" /* webpackChunkName: "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2019-09-learning-path-solutions-architects-mdx" */),
  "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2019-09-learning-path-testers-mdx": () => import("./../../../../gatsby-theme-blog/src/templates/post.js?__contentFilePath=/opt/build/repo/core/src/articles/2019/09/Learning Path - Testers.mdx" /* webpackChunkName: "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2019-09-learning-path-testers-mdx" */),
  "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2019-09-learning-path-user-experience-leadership-mdx": () => import("./../../../../gatsby-theme-blog/src/templates/post.js?__contentFilePath=/opt/build/repo/core/src/articles/2019/09/Learning Path - User Experience Leadership.mdx" /* webpackChunkName: "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2019-09-learning-path-user-experience-leadership-mdx" */),
  "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2019-09-learning-paths-for-agile-practitioners-mdx": () => import("./../../../../gatsby-theme-blog/src/templates/post.js?__contentFilePath=/opt/build/repo/core/src/articles/2019/09/Learning Paths for Agile Practitioners.mdx" /* webpackChunkName: "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2019-09-learning-paths-for-agile-practitioners-mdx" */),
  "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2019-09-stop-using-the-word-bug-mdx": () => import("./../../../../gatsby-theme-blog/src/templates/post.js?__contentFilePath=/opt/build/repo/core/src/articles/2019/09/Stop Using the Word 'Bug'.mdx" /* webpackChunkName: "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2019-09-stop-using-the-word-bug-mdx" */),
  "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2020-02-team-agreements-bias-toward-action-mdx": () => import("./../../../../gatsby-theme-blog/src/templates/post.js?__contentFilePath=/opt/build/repo/core/src/articles/2020/02/Team Agreements - Bias toward Action.mdx" /* webpackChunkName: "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2020-02-team-agreements-bias-toward-action-mdx" */),
  "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2020-03-agile-team-knowledge-retrospective-mdx": () => import("./../../../../gatsby-theme-blog/src/templates/post.js?__contentFilePath=/opt/build/repo/core/src/articles/2020/03/Agile Team Knowledge Retrospective.mdx" /* webpackChunkName: "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2020-03-agile-team-knowledge-retrospective-mdx" */),
  "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2020-10-empowerment-retrospective-mdx": () => import("./../../../../gatsby-theme-blog/src/templates/post.js?__contentFilePath=/opt/build/repo/core/src/articles/2020/10/Empowerment Retrospective.mdx" /* webpackChunkName: "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2020-10-empowerment-retrospective-mdx" */),
  "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2021-01-no-longer-tolerate-blocked-or-waiting-states-mdx": () => import("./../../../../gatsby-theme-blog/src/templates/post.js?__contentFilePath=/opt/build/repo/core/src/articles/2021/01/No Longer Tolerate Blocked or Waiting States.mdx" /* webpackChunkName: "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2021-01-no-longer-tolerate-blocked-or-waiting-states-mdx" */),
  "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2021-07-release-schedule-antipattern-mdx": () => import("./../../../../gatsby-theme-blog/src/templates/post.js?__contentFilePath=/opt/build/repo/core/src/articles/2021/07/Release Schedule - Antipattern.mdx" /* webpackChunkName: "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2021-07-release-schedule-antipattern-mdx" */),
  "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2021-07-we-will-continuously-deploy-code-mdx": () => import("./../../../../gatsby-theme-blog/src/templates/post.js?__contentFilePath=/opt/build/repo/core/src/articles/2021/07/We Will Continuously Deploy Code.mdx" /* webpackChunkName: "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2021-07-we-will-continuously-deploy-code-mdx" */),
  "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2021-10-i-am-a-psk-with-pro-kanban-org-mdx": () => import("./../../../../gatsby-theme-blog/src/templates/post.js?__contentFilePath=/opt/build/repo/core/src/articles/2021/10/I am a PSK with ProKanban.org.mdx" /* webpackChunkName: "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2021-10-i-am-a-psk-with-pro-kanban-org-mdx" */),
  "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2021-12-sometimes-transparency-just-means-not-lying-mdx": () => import("./../../../../gatsby-theme-blog/src/templates/post.js?__contentFilePath=/opt/build/repo/core/src/articles/2021/12/Sometimes 'Transparency' Just Means Not Lying.mdx" /* webpackChunkName: "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2021-12-sometimes-transparency-just-means-not-lying-mdx" */),
  "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2022-06-somebody-just-made-up-sdlc-and-everyone-went-along-with-it-mdx": () => import("./../../../../gatsby-theme-blog/src/templates/post.js?__contentFilePath=/opt/build/repo/core/src/articles/2022/06/Somebody just made up SDLC and everyone went along with it.mdx" /* webpackChunkName: "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2022-06-somebody-just-made-up-sdlc-and-everyone-went-along-with-it-mdx" */),
  "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2022-08-what-is-kanban-mdx": () => import("./../../../../gatsby-theme-blog/src/templates/post.js?__contentFilePath=/opt/build/repo/core/src/articles/2022/08/What Is Kanban.mdx" /* webpackChunkName: "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2022-08-what-is-kanban-mdx" */),
  "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2022-08-what-is-scrum-mdx": () => import("./../../../../gatsby-theme-blog/src/templates/post.js?__contentFilePath=/opt/build/repo/core/src/articles/2022/08/What Is Scrum.mdx" /* webpackChunkName: "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2022-08-what-is-scrum-mdx" */),
  "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2023-02-agile-questions-live-stream-archive-mdx": () => import("./../../../../gatsby-theme-blog/src/templates/post.js?__contentFilePath=/opt/build/repo/core/src/articles/2023/02/Agile-Questions_LiveStream-Archive.mdx" /* webpackChunkName: "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2023-02-agile-questions-live-stream-archive-mdx" */),
  "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2023-06-design-agencies-are-fun-development-teams-are-stressful-mdx": () => import("./../../../../gatsby-theme-blog/src/templates/post.js?__contentFilePath=/opt/build/repo/core/src/articles/2023/06/Design Agencies Are Fun, Development Teams Are Stressful.mdx" /* webpackChunkName: "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2023-06-design-agencies-are-fun-development-teams-are-stressful-mdx" */),
  "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2023-07-taming-a-phoenix-mdx": () => import("./../../../../gatsby-theme-blog/src/templates/post.js?__contentFilePath=/opt/build/repo/core/src/articles/2023/07/Taming a Phoenix.mdx" /* webpackChunkName: "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2023-07-taming-a-phoenix-mdx" */),
  "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2023-08-agile-jokes-mdx": () => import("./../../../../gatsby-theme-blog/src/templates/post.js?__contentFilePath=/opt/build/repo/core/src/articles/2023/08/Agile Jokes.mdx" /* webpackChunkName: "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2023-08-agile-jokes-mdx" */),
  "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2023-10-batch-size-mdx": () => import("./../../../../gatsby-theme-blog/src/templates/post.js?__contentFilePath=/opt/build/repo/core/src/articles/2023/10/Batch size.mdx" /* webpackChunkName: "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2023-10-batch-size-mdx" */),
  "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2023-10-scrum-doesnt-work-here-mdx": () => import("./../../../../gatsby-theme-blog/src/templates/post.js?__contentFilePath=/opt/build/repo/core/src/articles/2023/10/Scrum Doesn't Work Here.mdx" /* webpackChunkName: "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2023-10-scrum-doesnt-work-here-mdx" */),
  "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2023-10-why-im-not-a-sa-fe-trainer-or-consultant-mdx": () => import("./../../../../gatsby-theme-blog/src/templates/post.js?__contentFilePath=/opt/build/repo/core/src/articles/2023/10/Why I'm NOT a SAFe trainer or consultant.mdx" /* webpackChunkName: "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2023-10-why-im-not-a-sa-fe-trainer-or-consultant-mdx" */),
  "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2023-11-first-90-days-as-a-scrum-master-mdx": () => import("./../../../../gatsby-theme-blog/src/templates/post.js?__contentFilePath=/opt/build/repo/core/src/articles/2023/11/First 90 Days as a Scrum Master.mdx" /* webpackChunkName: "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2023-11-first-90-days-as-a-scrum-master-mdx" */),
  "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2024-02-the-user-story-demystified-mdx": () => import("./../../../../gatsby-theme-blog/src/templates/post.js?__contentFilePath=/opt/build/repo/core/src/articles/2024/02/The User Story - Demystified.mdx" /* webpackChunkName: "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2024-02-the-user-story-demystified-mdx" */),
  "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2024-02-try-to-stop-using-jargon-mdx": () => import("./../../../../gatsby-theme-blog/src/templates/post.js?__contentFilePath=/opt/build/repo/core/src/articles/2024/02/Try To Stop Using Jargon.mdx" /* webpackChunkName: "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2024-02-try-to-stop-using-jargon-mdx" */),
  "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2024-03-a-historical-perspective-of-the-scrum-master-role-mdx": () => import("./../../../../gatsby-theme-blog/src/templates/post.js?__contentFilePath=/opt/build/repo/core/src/articles/2024/03/A Historical Perspective of the Scrum Master role.mdx" /* webpackChunkName: "component---gatsby-theme-blog-src-templates-post-js-content-file-path-src-articles-2024-03-a-historical-perspective-of-the-scrum-master-role-mdx" */),
  "component---src-gatsby-theme-2023-pages-index-mjs": () => import("./../../../src/gatsby-theme-2023/pages/index.mjs" /* webpackChunkName: "component---src-gatsby-theme-2023-pages-index-mjs" */),
  "component---src-gatsby-theme-blog-components-posts-js": () => import("./../../../src/gatsby-theme-blog/components/posts.js" /* webpackChunkName: "component---src-gatsby-theme-blog-components-posts-js" */),
  "component---src-pages-1-on-1-mjs": () => import("./../../../src/pages/1-on-1.mjs" /* webpackChunkName: "component---src-pages-1-on-1-mjs" */),
  "component---src-pages-404-mjs": () => import("./../../../src/pages/404.mjs" /* webpackChunkName: "component---src-pages-404-mjs" */),
  "component---src-pages-about-mjs": () => import("./../../../src/pages/about.mjs" /* webpackChunkName: "component---src-pages-about-mjs" */),
  "component---src-pages-classes-mjs": () => import("./../../../src/pages/classes.mjs" /* webpackChunkName: "component---src-pages-classes-mjs" */),
  "component---src-pages-contact-mjs": () => import("./../../../src/pages/contact.mjs" /* webpackChunkName: "component---src-pages-contact-mjs" */),
  "component---src-pages-mail-mjs": () => import("./../../../src/pages/mail.mjs" /* webpackChunkName: "component---src-pages-mail-mjs" */),
  "component---src-pages-phoenix-mjs": () => import("./../../../src/pages/phoenix.mjs" /* webpackChunkName: "component---src-pages-phoenix-mjs" */),
  "component---src-pages-private-training-mjs": () => import("./../../../src/pages/private-training.mjs" /* webpackChunkName: "component---src-pages-private-training-mjs" */),
  "component---src-pages-talks-mjs": () => import("./../../../src/pages/talks.mjs" /* webpackChunkName: "component---src-pages-talks-mjs" */),
  "component---src-pages-thanks-mjs": () => import("./../../../src/pages/thanks.mjs" /* webpackChunkName: "component---src-pages-thanks-mjs" */),
  "component---src-pages-videos-mjs": () => import("./../../../src/pages/videos.mjs" /* webpackChunkName: "component---src-pages-videos-mjs" */),
  "component---src-pages-workshops-mjs": () => import("./../../../src/pages/workshops.mjs" /* webpackChunkName: "component---src-pages-workshops-mjs" */),
  "component---src-templates-course-details-js": () => import("./../../../src/templates/course-details.js" /* webpackChunkName: "component---src-templates-course-details-js" */),
  "component---src-templates-course-practice-groups-js": () => import("./../../../src/templates/course-practice-groups.js" /* webpackChunkName: "component---src-templates-course-practice-groups-js" */),
  "component---src-templates-sku-details-js": () => import("./../../../src/templates/sku-details.js" /* webpackChunkName: "component---src-templates-sku-details-js" */)
}

